export var RoleList;
(function (RoleList) {
    RoleList["SuperAdmin"] = "SUPER_ADMIN";
    RoleList["Admin"] = "ADMIN";
    RoleList["Community"] = "COMMUNITY";
    RoleList["CampaignExecutive"] = "CAMPAIGN_EXECUTIVE";
    RoleList["SpvCampaignExecutive"] = "SPV_CAMPAIGN_EXECUTIVE";
    RoleList["Audit"] = "AUDIT";
    RoleList["Finance"] = "FINANCE";
    RoleList["FinanceInput"] = "FINANCE_INPUT";
    RoleList["CampaignAssistant"] = "CAMPAIGN_ASSISTANT";
    RoleList["Reqsi"] = "REQUEST_SENT_ITEM";
    RoleList["Appsi"] = "APPROVE_SENT_ITEM";
})(RoleList || (RoleList = {}));
export var roles = Object.values(RoleList);
export var permissions = {
    'general.view': [
        RoleList.SuperAdmin,
        RoleList.Admin,
        RoleList.Community,
        RoleList.CampaignExecutive,
        RoleList.SpvCampaignExecutive,
        RoleList.Finance,
        RoleList.Audit,
        RoleList.FinanceInput,
        RoleList.CampaignAssistant,
        RoleList.Appsi,
        RoleList.Reqsi,
    ],
    //add talent
    'talent.view': [
        RoleList.SuperAdmin,
        RoleList.Admin,
        RoleList.Community,
        RoleList.CampaignExecutive,
        RoleList.SpvCampaignExecutive,
        RoleList.Finance,
        RoleList.Audit,
        RoleList.FinanceInput,
        RoleList.CampaignAssistant
    ],
    'talent.add.view': [RoleList.CampaignExecutive],
    //community
    'community.view': [RoleList.Community],
    'community.distribution.view': [RoleList.Community],
    'community.distributionkeep.view': [RoleList.Community],
    'community.talentbelumdiblast.view': [RoleList.Community],
    'community.tambahtalentbaru.view': [RoleList.Community],
    //summary
    'summary.view': [RoleList.Community],
    'summary.blast.view': [RoleList.Community],
    'summary.blastdetail.view': [RoleList.Community],
    'summary.distribution.view': [RoleList.Community],
    'summary.distributiondetail.view': [RoleList.Community],
    'summary.detaildealing.view': [RoleList.Community],
    //kerjasama
    'job.view': [RoleList.CampaignExecutive, RoleList.SpvCampaignExecutive],
    'jobdetail.view': [
        RoleList.CampaignExecutive,
        RoleList.SpvCampaignExecutive,
        RoleList.Audit,
        RoleList.Finance,
    ],
    'job.repeat.view': [RoleList.CampaignExecutive],
    //barang
    'barang.view': [RoleList.CampaignExecutive, RoleList.Audit, RoleList.Appsi, RoleList.Reqsi, RoleList.SpvCampaignExecutive],
    'barang.talent.view': [RoleList.CampaignExecutive, RoleList.Audit, RoleList.SpvCampaignExecutive],
    'barang.nontalent.view': [RoleList.Appsi, RoleList.Reqsi],
    //transfer
    'transfer.view': [RoleList.Finance, RoleList.FinanceInput, RoleList.CampaignExecutive],
    'transfer.transfersecaragrup.view': [RoleList.CampaignExecutive],
    'transfer.transfer.view': [RoleList.Finance, RoleList.FinanceInput],
    //approval
    'approval.view': [RoleList.Audit, RoleList.SpvCampaignExecutive],
    'approval.job.view': [RoleList.Audit],
    'approval.address.view': [RoleList.Audit],
    'approval.bankaccount.view': [RoleList.Audit],
    'approval.transfer.view': [RoleList.Audit],
    'approval.sendproduct.view': [RoleList.Audit, RoleList.SpvCampaignExecutive],
    // sendproduct
    'sendProduct.view': [RoleList.Appsi, RoleList.Reqsi],
    //pajak
    'download.view': [RoleList.Audit, RoleList.Finance, RoleList.FinanceInput],
    'pajak.view': [RoleList.Audit, RoleList.Finance, RoleList.FinanceInput],
    //jadwal
    'jadwal.view': [RoleList.CampaignExecutive, RoleList.CampaignAssistant],
    'nojadwal.view': [RoleList.CampaignExecutive],
    //saldoteam
    'saldoteam.view': [RoleList.CampaignExecutive, RoleList.SpvCampaignExecutive],
    'saldoteam.add': [RoleList.SpvCampaignExecutive],
    //laporan perhitungan
    'laporanperhitungan.view': [RoleList.Admin, RoleList.SuperAdmin],
    'laporanperhitungan.laporanblast.view': [RoleList.Admin, RoleList.SuperAdmin],
    'laporanperhitungan.laporantransit.view': [RoleList.Admin, RoleList.SuperAdmin],
    'laporanperhitungan.laporandeal.view': [RoleList.Admin, RoleList.SuperAdmin],
    'laporanperhitungan.laporanrekapbarang.view': [RoleList.Admin, RoleList.SuperAdmin],
    'laporanperhitungan.laporanrekapbarangdikirim.view': [RoleList.Admin, RoleList.SuperAdmin],
    'laporanperhitungan.laporanup.view': [RoleList.Admin, RoleList.SuperAdmin],
    'laporanperhitungan.laporanpaidpromote.view': [RoleList.Admin, RoleList.SuperAdmin],
    //laporan pengeluaran
    'laporanpengeluaran.view': [RoleList.Admin, RoleList.SuperAdmin],
    'laporanpengeluaran.harian.view': [RoleList.Admin, RoleList.SuperAdmin],
    'laporanpengeluaran.bulanan.view': [RoleList.Admin, RoleList.SuperAdmin],
    //setting
    'setting.view': [RoleList.Admin, RoleList.SuperAdmin],
    'bank.view': [RoleList.Admin, RoleList.SuperAdmin],
    'bank.add': [RoleList.Admin, RoleList.SuperAdmin],
    'bank.edit': [RoleList.Admin, RoleList.SuperAdmin],
    'talentcategory.view': [RoleList.Admin, RoleList.SuperAdmin],
    'talentcategory.add': [RoleList.Admin, RoleList.SuperAdmin],
    'talentcategory.edit': [RoleList.Admin, RoleList.SuperAdmin],
    'team.view': [RoleList.Admin, RoleList.SuperAdmin],
    'team.add': [RoleList.Admin, RoleList.SuperAdmin],
    'team.edit': [RoleList.Admin, RoleList.SuperAdmin],
    'jobtype.view': [RoleList.Admin, RoleList.SuperAdmin],
    'jobtype.add': [RoleList.Admin, RoleList.SuperAdmin],
    'jobtype.edit': [RoleList.Admin, RoleList.SuperAdmin],
    'product.view': [RoleList.Admin, RoleList.SuperAdmin],
    'product.add': [RoleList.Admin, RoleList.SuperAdmin],
    'product.edit': [RoleList.Admin, RoleList.SuperAdmin],
    'user.view': [RoleList.Admin, RoleList.SuperAdmin],
    'user.add': [RoleList.Admin, RoleList.SuperAdmin],
    'user.edit': [RoleList.Admin, RoleList.SuperAdmin],
    'campaignproduct.view': [RoleList.Admin, RoleList.SuperAdmin],
    'campaignproduct.add': [RoleList.Admin, RoleList.SuperAdmin],
    'campaignproduct.edit': [RoleList.Admin, RoleList.SuperAdmin],
    'campaigndetailproduct.view': [RoleList.Admin, RoleList.SuperAdmin],
    'campaigndetailproduct.add': [RoleList.Admin, RoleList.SuperAdmin],
    'campaigndetailproduct.edit': [RoleList.Admin, RoleList.SuperAdmin],
    'campaignseriesproduct.view': [RoleList.Admin, RoleList.SuperAdmin],
    'campaignseriesproduct.add': [RoleList.Admin, RoleList.SuperAdmin],
    'campaignseriesproduct.edit': [RoleList.Admin, RoleList.SuperAdmin],
    'briefcategory.view': [RoleList.Admin, RoleList.SuperAdmin],
    'briefcategory.add': [RoleList.Admin, RoleList.SuperAdmin],
    'briefcategory.edit': [RoleList.Admin, RoleList.SuperAdmin],
};
export var userInformation = localStorage.getItem('userInformation');
