var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageTitle } from '@src/_metronic/layout/core';
import { RoleList, userInformation } from '@src/app/helper';
import '@src/app/styles/style.css';
import TForm from '@src/components/molecules/Talent/Form/TalentForm';
import { useFormik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTalentCategories } from '../../TalentCategoryPage/TalentCategoryHooks/TalentCategory.hooks';
import { useTalents } from '../TalentHooks/Talent.hooks';
var initialValues = {
    name: '',
    cpName: '',
    cpPhoneNumber: '',
    cpEmail: '',
    note: '',
    countryPubId: '',
    // isTalentBA: false,
    profiles: [
        {
            socialMediaPubId: '',
            profileName: '',
            profileLink: '',
            isBlasted: true,
        },
    ],
    categoryPubIds: null,
    // isBlackListed: true,
    // recordStatus: '',
    tenantPubId: '',
    currencyPubId: '',
    religionPubId: '',
    // dob: '',
    // jobTypePubId: '',
    ktpNumber: '',
    ktpName: '',
    npwpNumber: '',
    npwpName: '',
};
var TalentFormSchema = Yup.object().shape({
    name: Yup.string().required('Field belum diisi'),
    cpName: Yup.string().required('Field belum diisi'),
    cpPhoneNumber: Yup.string()
        .min(10, 'Minimal 10 karakter')
        .max(16, 'Maksimal 13 karakter')
        .matches(/^(\S+$)/g, 'Tidak boleh ada spasi')
        .required('Field belum diisi'),
    cpEmail: Yup.string().matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Email tidak valid'),
    profiles: Yup.array().of(Yup.object().shape({
        profileName: Yup.string().required('Field belum diisi'),
        profileLink: Yup.string().required('Field belum diisi'),
    })),
});
var TalentForm = function () {
    var role = JSON.parse(userInformation).roles[0];
    var tenantId = JSON.parse(userInformation).tenant.pubId;
    var navigate = useNavigate();
    var id = useParams().id;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), selectedTalentCategory = _b[0], setSelectedTalentCategory = _b[1];
    var _c = useState(initialValues), initialFormValues = _c[0], setInitialFormValues = _c[1];
    var _d = useTalents(), createTalent = _d.createTalent, updateTalent = _d.updateTalent;
    var talentDetail = []; //for add only
    var _e = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '',
        initialFilterBy: '',
    }), filterPageSize = _e[0], setFilterPageSize = _e[1];
    var _f = useTalentCategories(filterPageSize), talentCategories = _f.talentCategories, isLoadingCategories = _f.isLoading, errorLoadingCategories = _f.error;
    var optionTalentCategory = talentCategories &&
        talentCategories.data.length > 0 &&
        talentCategories.data.map(function (category) { return ({
            label: category.name,
            value: category.pubId,
        }); });
    var talentValues = {
        name: '',
        cpName: '',
        cpPhoneNumber: '',
        cpEmail: '',
        note: '',
        tenantPubId: '',
        countryPubId: '',
        currencyPubId: '',
        religionPubId: '',
        // isTalentBA: false,
        profiles: [
            {
                socialMediaPubId: '',
                profileName: '',
                profileLink: '',
                isBlasted: true,
            },
        ],
        categoryPubIds: null,
        // isBlackListed: true,
        // recordStatus: '',
        ktpNumber: '',
        ktpName: '',
        npwpNumber: '',
        npwpName: '',
    };
    var TalentFormik = useFormik({
        enableReinitialize: true,
        initialValues: talentDetail ? talentValues : initialFormValues,
        validationSchema: TalentFormSchema,
        onSubmit: function (values, _a) {
            var setErrors = _a.setErrors, setStatus = _a.setStatus, setSubmitting = _a.setSubmitting;
            return __awaiter(void 0, void 0, void 0, function () {
                var e_1, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            setLoading(true);
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, , 4]);
                            values.tenantPubId = tenantId; //tenantId from userinformation
                            return [4 /*yield*/, createTalent(__assign({}, values))];
                        case 2:
                            _b.sent();
                            {
                                role === RoleList.CampaignExecutive ? navigate('/talent/all-talents') : navigate('/blast');
                            }
                            setLoading(false);
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _b.sent();
                            error = e_1.response.data;
                            setStatus(error.message);
                            setSubmitting(false);
                            setLoading(false);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var handleAdd = function () {
        var newProfile = {
            socialMediaPubId: '',
            profileName: '',
            profileLink: '',
            isBlasted: true,
        };
        setInitialFormValues(function (prevState) { return (__assign(__assign({}, prevState), { profiles: __spreadArray(__spreadArray([], prevState.profiles, true), [newProfile], false) })); });
    };
    var handleRemove = function (index) {
        var updatedProfile = __spreadArray([], initialFormValues.profiles, true);
        updatedProfile.splice(index, 1);
        setInitialFormValues(function (prevState) { return (__assign(__assign({}, prevState), { profiles: updatedProfile })); });
        TalentFormik.values.profiles = updatedProfile;
    };
    var onChangeTalentCategory = function (e) {
        setSelectedTalentCategory(e);
        var selected = e.map(function (obj) { return obj.value; });
        TalentFormik.setFieldValue('categoryPubIds', selected);
    };
    var _g = useState([]), selectedCountry = _g[0], setSelectedCountry = _g[1];
    var onChangeCountry = function (e) {
        setSelectedCountry(e);
        TalentFormik.setFieldValue('countryPubId', e.value);
    };
    useEffect(function () {
        setInitialFormValues(TalentFormik.values);
    }, [TalentFormik.values]);
    useEffect(function () {
        var _a;
        //set roles
        if (talentDetail === null || talentDetail === void 0 ? void 0 : talentDetail.categories) {
            var arr = Object(talentDetail);
            var selected = (_a = arr.roles) === null || _a === void 0 ? void 0 : _a.map(function (obj) { return ({
                label: obj,
                value: obj,
            }); });
            setSelectedTalentCategory(selected);
        }
    }, [talentValues.categoryPubIds]);
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, { children: id ? 'Edit Talent' : 'Tambah Talent' }), _jsx("div", __assign({ className: 'row g-5' }, { children: _jsx("div", __assign({ className: 'col-lg-6' }, { children: _jsx("div", __assign({ className: 'card card-flush shadow-sm' }, { children: _jsx("div", __assign({ className: 'card-body p-0' }, { children: _jsxs("form", __assign({ className: 'form w-100', onSubmit: TalentFormik.handleSubmit, 
                                // noValidate
                                id: 'talent-create-form' }, { children: [_jsx(TForm, { useForm: TalentFormik, formValues: initialFormValues, addSocialMedia: handleAdd, removeSocialMedia: handleRemove, selectedTalentCategory: selectedTalentCategory, onChangeTalentCategory: onChangeTalentCategory, isLoadingTalentCategory: isLoadingCategories, optionTalentCategory: optionTalentCategory, selectedCountry: selectedCountry, onChangeCountry: onChangeCountry }), _jsxs("div", __assign({ className: 'button-container' }, { children: [_jsx("div", { children: TalentFormik.status }), _jsx(Link, __assign({ to: '/talent/' }, { children: _jsx("button", __assign({ className: 'btn btn-light' }, { children: "Kembali" })) })), _jsxs("button", __assign({ type: 'submit', id: 'kt_talent_submit', className: 'btn btn-primary ', disabled: TalentFormik.isSubmitting }, { children: [!loading && _jsx("span", __assign({ className: 'indicator-label' }, { children: "Simpan" })), loading && (_jsxs("span", __assign({ className: 'indicator-progress', style: { display: 'block' } }, { children: ["Mohon tunggu...", _jsx("span", { className: 'spinner-border spinner-border-sm align-middle ms-2' })] })))] }))] }))] })) })) })) })) }))] }));
};
export default TalentForm;
