var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuComponent } from '@src/_metronic/assets/ts/components';
import { KTCard } from '@src/_metronic/helpers';
import { PageTitle } from '@src/_metronic/layout/core';
import { MultiDimensionalFilter, ProtectedUrl, RoleList, paginationOptions, termStatusOptions, transferOptions, userInformation, } from '@src/app/helper';
import '@src/app/styles/datatable.css';
import TransferActionDropdown from '@src/components/Atoms/ActionDropdown/TransferActionDropdown';
import Input from '@src/components/Atoms/Input/Input';
import Loading from '@src/components/Atoms/Loading/Loading';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Select from '@src/components/Atoms/Select/Select';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import RecordTransfer from '@src/components/molecules/Record/Collaboration';
import RecordTalent from '@src/components/molecules/Record/Talent';
import Process from '@src/components/molecules/Transfer/Process';
import { Fragment, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTransferPerTerm } from './Transfer.hooks';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Transfer',
        path: '/transfer/all-transfer#NotYetTransfer',
        isSeparator: false,
        isActive: false,
    },
];
var TransferPerTermIndex = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var role = JSON.parse(userInformation).roles[0];
    var _a = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
    }), filterSearch = _a[0], setFilterSearch = _a[1];
    var _b = useState(role == RoleList.CampaignExecutive ? transferOptions.pertermin : transferOptions.input), transferType = _b[0], setTransferType = _b[1];
    var _c = useState(), talentRecord = _c[0], setTalentRecord = _c[1];
    var _d = useState(false), isOpenProcessSettings = _d[0], setIsOpenProcessSettings = _d[1];
    var _e = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
        Type: role == RoleList.CampaignExecutive ? transferOptions.pertermin : transferOptions.input,
    }), filter = _e[0], setFilter = _e[1];
    var _f = useState(''), selectedSocialMediaId = _f[0], setSelectedSocialMediaId = _f[1];
    var _g = useTransferPerTerm(filter), transfers = _g.transfers, isLoading = _g.isLoading, refetchTransfers = _g.refetch;
    var divisions = JSON.parse(userInformation).divisions;
    var teamOptions = useMemo(function () {
        try {
            var arr = JSON.parse(userInformation).teams || [];
            return arr.map(function (_a) {
                var name = _a.name, pubId = _a.pubId;
                return ({ name: name, value: pubId });
            });
        }
        catch (err) {
            console.error('Error while parsing teams:', err);
            return [];
        }
    }, []);
    var socialMediaOptions = useMemo(function () {
        try {
            var arr = JSON.parse(userInformation).socialMedias || [];
            return arr.map(function (_a) {
                var name = _a.name, pubId = _a.pubId;
                return ({ name: name, value: pubId });
            });
        }
        catch (err) {
            console.error('Error while parsing teams:', err);
            return [];
        }
    }, []);
    var optionDivision = role === RoleList.CampaignExecutive
        ? divisions &&
            divisions.map(function (division, index) { return ({
                name: "".concat(division.name, " - ").concat(socialMediaOptions && socialMediaOptions[index].name),
                value: division.pubId,
            }); })
        : divisions &&
            divisions.map(function (division) { return ({
                name: "".concat(division.name, " - ").concat(division.socialMedia.name),
                value: division.pubId,
            }); });
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
            setSelectedSocialMediaId(event.target.value);
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    var handleProcessTalent = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setTalentRecord(record);
            setIsOpenProcessSettings(true);
            return [2 /*return*/];
        });
    }); };
    // TODO: show cp email and cp other and country name on datatable row, as its missing from api return
    var columns = [
        {
            name: 'Contact Person',
            cell: function (record) {
                return (_jsx(RecordTalent, { name: record.talent.name, pubId: record.talent.pubId, cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber, cpEmail: record.talent.cpEmail, onClick: gotoDetail, profiles: [] }));
            },
            sortable: true,
        },
        {
            name: 'Kerja Sama',
            cell: function (record) {
                return _jsx(RecordTransfer, { record: record.jobs[0] || '', id: record.jobs[0].pubId });
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) {
                return _jsx(RecordAdmin, { record: record.jobs[0] });
            },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsx(_Fragment, { children: ProtectedUrl('transfer.view') && transferType == transferOptions.pertermin && (_jsx(TransferActionDropdown, { handleProcess: handleProcessTalent, record: record, transferType: transferType }) //transfer per termin
                    ) }));
            },
            width: '220px',
            center: true,
        },
    ];
    useEffect(function () {
        MenuComponent.reinitialization();
        if (!location.hash) {
            if (role == RoleList.CampaignExecutive) {
                navigate("#".concat(transferOptions.pertermin));
            }
            else {
                navigate("#".concat(transferOptions.input));
            }
        }
    }, []);
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (socialMediaOptions.length) {
            filterValue.push(['division.socialMedia.pubId', socialMediaOptions[0].value]);
        }
        if (teamOptions.length) {
            filterValue.push(['team.pubId', teamOptions[0].value]);
        }
        setFilter(function (prev) { return (__assign(__assign({}, prev), { filterBy: JSON.stringify(filterValue) })); });
    }, [teamOptions]);
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Transfer Per Term" })), _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                handleFilterChange(e);
                                            } }) })), _jsx("div", __assign({ className: 'card-title' }, { children: socialMediaOptions.length > 0 && (_jsx(Select, { name: 'division.socialMedia.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, optionList: socialMediaOptions, defaultValue: socialMediaOptions[0].value })) })), _jsx("div", __assign({ className: 'card-title' }, { children: ProtectedUrl('transfer.view') &&
                                            transferType == transferOptions.pertermin &&
                                            optionDivision &&
                                            optionDivision.length > 0 && (_jsx(Select, { name: 'division.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, optionList: optionDivision })) })), _jsx("div", __assign({ className: 'card-title' }, { children: teamOptions.length > 0 && (_jsx(Select, { name: 'team.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Team', optionList: teamOptions, defaultValue: teamOptions[0].value || '' })) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'termStatusOption', onChange: function (e) {
                                                handleFilterChange(e);
                                            }, optionList: termStatusOptions }) }))] })) })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: transfers ? transfers.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                                ? transfers && transfers.recordsTotal
                                : transfers && transfers.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, transfers && transfers.data.length) }))] }), talentRecord && (_jsx(Process, { isOpen: isOpenProcessSettings, handleClose: function () {
                    setTalentRecord('');
                    setIsOpenProcessSettings(false);
                }, record: talentRecord }))] }));
};
export default TransferPerTermIndex;
