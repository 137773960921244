var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from 'axios';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from 'react-query';
var baseApiUrl = process.env.NEXUS_API_URL;
//job start from here
// approve job
function approveJob(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/jobs/").concat(job.pubId, "/approve"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// reject job
function rejectJob(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/jobs/").concat(job.id, "/cancel"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useJob(id) {
    var queryClient = useQueryClient();
    var approveJobMutation = useMutation(approveJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('job', function (oldJobs) {
                if (oldJobs === void 0) { oldJobs = []; }
                return __spreadArray(__spreadArray([], oldJobs, true), [newJob], false);
            });
            toast.success('Kerja sama berhasil diapprove');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Kerja sama gagal diapprove: ".concat(error.message));
        },
    });
    var rejectJobMutation = useMutation(rejectJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('job', function (oldJobs) {
                if (oldJobs === void 0) { oldJobs = []; }
                return __spreadArray(__spreadArray([], oldJobs, true), [newJob], false);
            });
            toast.success('Kerja sama berhasil direject');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Kerja sama gagal direject: ".concat(error.message));
        },
    });
    return {
        approveJob: approveJobMutation.mutateAsync,
        rejectJob: rejectJobMutation.mutateAsync,
    };
}
//transfer start from here
// approve transfer
function approveTransfer(transfer) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = transfer.id, values = __rest(transfer, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(transfer.pubId, "/approve"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function undoApprovalTransfer(transferPubId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(transferPubId, "/undo-approve"))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function undoTransfer(transferPubId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(transferPubId, "/undo-transfer"))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// reject transfer
function rejectTransfer(transfer) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = transfer.id, values = __rest(transfer, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(transfer.id, "/reject"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useTransfer(id) {
    var queryClient = useQueryClient();
    var approveTransferMutation = useMutation(approveTransfer, {
        onSuccess: function (newTransfer) {
            queryClient.setQueryData('transfer', function (oldTransfers) {
                if (oldTransfers === void 0) { oldTransfers = []; }
                return __spreadArray(__spreadArray([], oldTransfers, true), [
                    newTransfer,
                ], false);
            });
            toast.success('Transfer berhasil diapprove');
            queryClient.refetchQueries({ queryKey: 'transfers' });
        },
        onError: function (error) {
            toast.error("Transfer gagal diapprove: ".concat(error.message));
        },
    });
    var undoApprovalTransferMutation = useMutation(undoApprovalTransfer, {
        onSuccess: function (newTransfer) {
            queryClient.setQueryData('transfer', function (oldTransfers) {
                if (oldTransfers === void 0) { oldTransfers = []; }
                return __spreadArray(__spreadArray([], oldTransfers, true), [
                    newTransfer,
                ], false);
            });
            toast.success('Approval Transfer berhasil di-undo');
            queryClient.refetchQueries({ queryKey: 'transfers' });
        },
        onError: function (error) {
            toast.error("Approval Transfer gagal di-undo: ".concat(error.message));
        },
    });
    var undoTransferMutation = useMutation(undoTransfer, {
        onSuccess: function (newTransfer) {
            queryClient.setQueryData('transfer', function (oldTransfers) {
                if (oldTransfers === void 0) { oldTransfers = []; }
                return __spreadArray(__spreadArray([], oldTransfers, true), [
                    newTransfer,
                ], false);
            });
            toast.success('Transfer berhasil di-undo');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Transfer gagal di-undo: ".concat(error.message));
        },
    });
    var rejectTransferMutation = useMutation(rejectTransfer, {
        onSuccess: function (newTransfer) {
            queryClient.setQueryData('transfer', function (oldTransfers) {
                if (oldTransfers === void 0) { oldTransfers = []; }
                return __spreadArray(__spreadArray([], oldTransfers, true), [
                    newTransfer,
                ], false);
            });
            toast.success('Transfer berhasil direject');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Transfer gagal direject: ".concat(error.message));
        },
    });
    return {
        approveTransfer: approveTransferMutation.mutateAsync,
        undoApprovalTransfer: undoApprovalTransferMutation.mutateAsync,
        undoTransfer: undoTransferMutation.mutateAsync,
        rejectTransfer: rejectTransferMutation.mutateAsync,
    };
}
//address start from here
// approve address
function approveAddress(address) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = address.id, values = __rest(address, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/talents/").concat(id, "/addresses/").concat(address.pubId, "/approve"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// reject address
function rejectAddress(address) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = address.id, values = __rest(address, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/talents/").concat(id, "/addresses/").concat(address.pubId, "/reject"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useAddress(id) {
    var queryClient = useQueryClient();
    var approveAddressMutation = useMutation(approveAddress, {
        onSuccess: function (newAddress) {
            queryClient.setQueryData('address', function (oldAddresss) {
                if (oldAddresss === void 0) { oldAddresss = []; }
                return __spreadArray(__spreadArray([], oldAddresss, true), [
                    newAddress,
                ], false);
            });
            toast.success('Alamat berhasil diapprove');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Alamat gagal diapprove: ".concat(error.message));
        },
    });
    var rejectAddressMutation = useMutation(rejectAddress, {
        onSuccess: function (newAddress) {
            queryClient.setQueryData('address', function (oldAddresss) {
                if (oldAddresss === void 0) { oldAddresss = []; }
                return __spreadArray(__spreadArray([], oldAddresss, true), [
                    newAddress,
                ], false);
            });
            toast.success('Alamat berhasil direject');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Alamat gagal direject: ".concat(error.message));
        },
    });
    return {
        approveAddress: approveAddressMutation.mutateAsync,
        rejectAddress: rejectAddressMutation.mutateAsync,
    };
}
//bankaccount start from here
// approve bankAccount
function approveBankAccount(bankAccount) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = bankAccount.id, values = __rest(bankAccount, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/talents/").concat(id, "/banks/").concat(bankAccount.pubId, "/approve"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// reject bankAccount
function rejectBankAccount(bankAccount) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = bankAccount.id, values = __rest(bankAccount, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/talents/").concat(id, "/banks/").concat(bankAccount.pubId, "/reject"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useBankAccount(id) {
    var queryClient = useQueryClient();
    var approveBankAccountMutation = useMutation(approveBankAccount, {
        onSuccess: function (newBankAccount) {
            queryClient.setQueryData('bankAccount', function (oldBankAccounts) {
                if (oldBankAccounts === void 0) { oldBankAccounts = []; }
                return __spreadArray(__spreadArray([], oldBankAccounts, true), [
                    newBankAccount,
                ], false);
            });
            toast.success('No rekening berhasil diapprove');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("No rekening gagal diapprove: ".concat(error.message));
        },
    });
    var rejectBankAccountMutation = useMutation(rejectBankAccount, {
        onSuccess: function (newBankAccount) {
            queryClient.setQueryData('bankAccount', function (oldBankAccounts) {
                if (oldBankAccounts === void 0) { oldBankAccounts = []; }
                return __spreadArray(__spreadArray([], oldBankAccounts, true), [
                    newBankAccount,
                ], false);
            });
            toast.success('No rekening berhasil direject');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("No rekening gagal direject: ".concat(error.message));
        },
    });
    return {
        approveBankAccount: approveBankAccountMutation.mutateAsync,
        rejectBankAccount: rejectBankAccountMutation.mutateAsync,
    };
}
//sendproduct start from here
// approve sendProduct
function approveSendProduct(sendProduct) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = sendProduct.id, values = __rest(sendProduct, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/send-products/").concat(sendProduct.pubId, "/approve"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// reject sendProduct
function rejectSendProduct(sendProduct) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = sendProduct.id, values = __rest(sendProduct, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/send-products/").concat(sendProduct.id, "/reject"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useSendProduct(id) {
    var queryClient = useQueryClient();
    var approveSendProductMutation = useMutation(approveSendProduct, {
        onSuccess: function (newSendProduct) {
            queryClient.setQueryData('sendProduct', function (oldSendProducts) {
                if (oldSendProducts === void 0) { oldSendProducts = []; }
                return __spreadArray(__spreadArray([], oldSendProducts, true), [
                    newSendProduct,
                ], false);
            });
            toast.success('Kirim barang berhasil diapprove');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Kirim barang gagal diapprove: ".concat(error.message));
        },
    });
    var rejectSendProductMutation = useMutation(rejectSendProduct, {
        onSuccess: function (newSendProduct) {
            queryClient.setQueryData('sendProduct', function (oldSendProducts) {
                if (oldSendProducts === void 0) { oldSendProducts = []; }
                return __spreadArray(__spreadArray([], oldSendProducts, true), [
                    newSendProduct,
                ], false);
            });
            toast.success('Kirim barang berhasil direject');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Kirim barang gagal direject: ".concat(error.message));
        },
    });
    return {
        approveSendProduct: approveSendProductMutation.mutateAsync,
        rejectSendProduct: rejectSendProductMutation.mutateAsync,
    };
}
//buktiupload start from here
// edit insight
function insightBuktiUpload(Insight) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = Insight.id, values = __rest(Insight, ["id"]);
                    return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/schedules/").concat(id, "/insight"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useBuktiUpload(id) {
    var queryClient = useQueryClient();
    var insightBuktiUploadMutation = useMutation(insightBuktiUpload, {
        onSuccess: function (newBuktiUpload) {
            queryClient.setQueryData('insight', function (oldBuktiUploads) {
                if (oldBuktiUploads === void 0) { oldBuktiUploads = []; }
                return __spreadArray(__spreadArray([], oldBuktiUploads, true), [
                    newBuktiUpload,
                ], false);
            });
            toast.success('Insight berhasil diubah');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Insight gagal diubah: ".concat(error.message));
        },
    });
    return {
        insightBuktiUpload: insightBuktiUploadMutation.mutateAsync,
    };
}
//complete-uncomplete start from here
// complete
function complete(id) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/jobs/").concat(id, "/complete"))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// uncomplete
function uncomplete(id) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.put("".concat(baseApiUrl, "/jobs/").concat(id, "/un-complete"))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useComplete(id) {
    var queryClient = useQueryClient();
    var completeMutation = useMutation(complete, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('job', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [
                    newJob,
                ], false);
            });
            toast.success('Complete Job berhasil');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Complete Job gagal: ".concat(error.message));
        },
    });
    var uncompleteMutation = useMutation(uncomplete, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('talent', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [
                    newJob,
                ], false);
            });
            toast.success('Uncompleted Job berhasil');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Uncomplete Job gagal: ".concat(error.message));
        },
    });
    return {
        complete: completeMutation.mutateAsync,
        uncomplete: uncompleteMutation.mutateAsync,
    };
}
//generate-tax start from here
export function generateTax(id, values) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/jobs/").concat(id, "/generate-tax"), values)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function useGenerateTax(id, values) {
    var _this = this;
    var _a = useQuery(['taxFee'], function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, generateTax(id, values)];
    }); }); }), taxFee = _a.data, isLoading = _a.isLoading, error = _a.error, refetch = _a.refetch;
    return {
        taxFee: taxFee,
        isLoading: isLoading,
        error: error,
        refetch: refetch,
    };
}
