export var DefaultAllList = {
    name: 'Semua',
    value: '',
};
export var DefaultAllMultiList = {
    value: '',
    label: 'Semua'
};
export var DefaultNotList = {
    name: '',
    value: '',
};
