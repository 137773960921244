var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { RoleList, userInformation } from '@src/app/helper';
import { Fragment } from 'react';
var SendProductActionDropdown = function (_a) {
    var _b;
    var record = _a.record, _c = _a.handleEdit, handleEdit = _c === void 0 ? function () { } : _c, _d = _a.handleSent, handleSent = _d === void 0 ? function () { } : _d, _e = _a.handleApprove, handleApprove = _e === void 0 ? function () { } : _e, _f = _a.handleReject, handleReject = _f === void 0 ? function () { } : _f, _g = _a.handleDetail, handleDetail = _g === void 0 ? function () { } : _g, id = _a.id;
    var role = JSON.parse(userInformation).roles[0];
    return (_jsx(Fragment, { children: _jsxs("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: [_jsx("button", __assign({ type: 'button', onClick: function () { return handleDetail(record); }, className: 'btn btn-primary btn-active-light-primary mr-2 btn-sm' }, { children: "Detail" })), (role === RoleList.Reqsi || role === RoleList.CampaignExecutive) && !record.isApproved ? (_jsx("button", __assign({ type: 'button', onClick: function () { return handleEdit(record); }, className: 'btn btn-secondary btn-active-light-primary mr-2 btn-sm' }, { children: "Edit" }))) : undefined, (role === RoleList.Audit || role === RoleList.SpvCampaignExecutive) && !record.isSent && !((_b = record.job) === null || _b === void 0 ? void 0 : _b.isHold) ? (_jsx("button", __assign({ type: 'button', onClick: function () { return handleSent(record); }, className: 'btn btn-success btn-active-light-primary mr-2 btn-sm' }, { children: "Process" }))) : undefined, role === RoleList.Appsi ? (_jsxs(_Fragment, { children: [!record.isApproved && !record.isRejected && !record.isSent && (_jsx("button", __assign({ type: 'button', onClick: function () { return handleApprove(record); }, className: 'btn btn-success btn-active-light-primary mr-2 btn-sm' }, { children: "Approve" }))), !record.isSent && record.isApproved && !record.isRejected && (_jsx("button", __assign({ type: 'button', onClick: function () { return handleSent(record); }, className: 'btn btn-success btn-active-light-primary mr-2 btn-sm' }, { children: "Sent" }))), !record.isRejected && !record.isApproved && !record.isSent && (_jsx("button", __assign({ type: 'button', onClick: function () { return handleReject(record); }, className: 'btn btn-danger btn-active-light-primary mr-2 btn-sm' }, { children: "Reject" })))] })) : undefined] })) }));
};
export default SendProductActionDropdown;
