var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, Fragment, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import { paginationOptions, RoleList, userInformation } from '@src/app/helper';
import { useNavigate } from 'react-router-dom';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import { PajakCategory } from '@src/components/molecules/Transfer/Transfer.enum';
import Loading from '@src/components/Atoms/Loading/Loading';
import UndoApprovalButton from '@src/app/modules/transfer/UndoApprovalButton';
import UndoTransferButton from '@src/app/modules/transfer/UndoTransferButton';
import RecordDescription from '@src/components/molecules/Record/Description';
import { ApprovalTransferDrawerForm } from '@src/app/pages/ApprovalPage/Approval/ApprovalTransfer/ApprovalTransferDrawerForm';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { ApprovalFormSchema } from '@src/app/pages/ApprovalPage/Approval/ApprovalTransfer/ApprovalTransfer';
import { toast } from 'react-toastify';
import { useTransfer } from '../../JobHooks/JobDetail.hooks';
var pajakCategory = function (status) {
    switch (status) {
        case PajakCategory.PPH21:
            return 'PPH 21 ';
        case PajakCategory.PPH23:
            return 'PPH 23';
        case PajakCategory.PPH23PLUSPPN:
            return 'PPH 23 Plus PPN';
        case PajakCategory.PPH26:
            return 'PPH 26';
        case PajakCategory.PPh23PlusPPNInc:
            return 'PPH 23 Plus PPN Include';
        case PajakCategory.PPh23PlusPPNExc:
            return 'PPH 23 Plus PPN Exclude';
        case PajakCategory.PPh4ayat2:
            return 'PPh 4ayat 2';
        case PajakCategory.PPh4ayat2PlusPPNInc:
            return 'PPh 4ayat 2 Plus PPN Include';
        case PajakCategory.PPh4ayat2PlusPPNExc:
            return 'PPh 4ayat 2 Plus PPN Exclude';
        case PajakCategory.PPh24PlusPPNInc:
            return 'PPh 24 Plus PPN Include';
        case PajakCategory.PPh24PlusPPNExc:
            return 'PPh 24 Plus PPN Exclude';
        case PajakCategory.PPH2620Percent:
            return 'PPH 26 20%';
        default:
            break;
    }
};
var Transfer = function (_a) {
    var datas = _a.datas, isLoading = _a.isLoading;
    var navigate = useNavigate();
    var _b = useState(), selectedApproval = _b[0], setSelectedApproval = _b[1];
    var _c = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
    }), filter = _c[0], setFilter = _c[1];
    var approveTransfer = useTransfer('').approveTransfer;
    // if createdOn is less than today, default is today
    // else createdOn
    var minDateApproval = useMemo(function () {
        if (!selectedApproval)
            return dayjs();
        var today = dayjs();
        if (dayjs(selectedApproval.createdOn * 1000).isBefore(today, 'date')) {
            return today;
        }
        return dayjs(selectedApproval.createdOn * 1000);
    }, [selectedApproval]);
    // If today is more than createdOn + 13 than default is today
    // else createdOn + 13
    var defaultDateApproval = useMemo(function () {
        if (!selectedApproval)
            return '';
        var today = dayjs();
        if (today.isAfter(dayjs(selectedApproval.createdOn * 1000).add(13, 'day'))) {
            return today.format('YYYY-MM-DD');
        }
        else {
            return dayjs(selectedApproval.createdOn * 1000)
                .add(13, 'day')
                .format('YYYY-MM-DD');
        }
    }, [selectedApproval]);
    var ApprovalFormik = useFormik({
        initialValues: {
            paymentDue: defaultDateApproval,
        },
        enableReinitialize: true,
        validationSchema: ApprovalFormSchema,
        onSubmit: function (_a) {
            var paymentDue = _a.paymentDue;
            return __awaiter(void 0, void 0, void 0, function () {
                var e_1, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!selectedApproval)
                                return [2 /*return*/];
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, approveTransfer(__assign(__assign({ id: selectedApproval.talent.pubId }, selectedApproval), { paymentDue: paymentDue }))];
                        case 2:
                            _b.sent();
                            return [3 /*break*/, 5];
                        case 3:
                            e_1 = _b.sent();
                            error = e_1.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 5];
                        case 4:
                            setSelectedApproval(undefined);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    var role = JSON.parse(userInformation).roles[0];
    var columns = [
        {
            name: 'No.Transfer',
            cell: function (record) { return record.transferNumber; },
        },
        {
            name: 'Bank',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsxs("p", { children: [record.talentBankAccount.bank.name, record.talentBankAccount.city && " - ".concat(record.talentBankAccount.city.name)] }), _jsx("p", { children: record.talentBankAccount.accountNumber }), _jsx("p", { children: record.talentBankAccount.accountName })] }));
            },
            sortable: true,
        },
        {
            name: 'Total Transfer',
            cell: function (record) {
                var _a;
                return (_jsx("div", { children: _jsxs("p", { children: ["Rp ", (_a = record.totalAmount.toLocaleString()) !== null && _a !== void 0 ? _a : 0] }) }));
            },
            sortable: true,
        },
        {
            name: 'Keterangan',
            cell: function (record) {
                return (_jsx(RecordDescription, { pajakType: record.pajakType, pajakMethod: record.pajakMethod, pajakCategory: record.pajakCategory, paymentMethod: record.paymentMethod, pajakFee: record.pajakFee, pajakBruto: record.pajakBruto, pajakPPN: record.pajakPPN, bankTransfer: record.bankTransferBy ? record.bankTransferBy.name : '-', invoice: record.invoiceUrl, spk: record.spkUrl, transfer: record.transferUrl, proofTransfer: record.proofTransferUrl, isCanceled: record.isCanceled }));
            },
            sortable: true,
        },
        {
            name: 'Note',
            cell: function (record) {
                var _a;
                return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: (_a = record.note) !== null && _a !== void 0 ? _a : '-' })), record.canceledReason && (_jsxs("div", __assign({ className: 'd-flex gap-2 flex-column mt-5' }, { children: ["Alasan Cancel: ", record.canceledReason] })))] }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record && record }); },
            sortable: true,
        },
        {
            name: 'Action',
            omit: ![RoleList.Audit, RoleList.Finance].includes(role),
            cell: function (record) {
                return (_jsxs(_Fragment, { children: [_jsx(UndoApprovalButton, { transferPubId: record.pubId, transfer: record }), _jsx(UndoTransferButton, { transferPubId: record.pubId, transfer: record }), !record.isApproved && (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary', onClick: function () { return setSelectedApproval(record); } }, { children: "Approve" })))] }));
            },
        },
    ];
    return (_jsxs(Fragment, { children: [selectedApproval && (_jsx(ApprovalTransferDrawerForm, { open: true, formik: ApprovalFormik, minDate: minDateApproval, onClose: function () { return setSelectedApproval(undefined); } })), _jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'card-body py-0' }, { children: !isLoading ? (datas && datas.data && datas.data.length > 0 ? (_jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: datas ? datas.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                            ? datas && datas.recordsTotal
                            : datas && datas.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, datas && datas.data.length)) : (_jsx(NotFound, {}))) : (_jsx(Loading, {})) })) }))] }));
};
export default Transfer;
