var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, Fragment, useEffect, createContext } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard, KTSVG } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { FormatDateTime, paginationOptions, MultiDimensionalFilter, userInformation, optionRekapList, RoleList, FormatYearMonthDate, DefaultAllList, } from '@src/app/helper';
import Input from '@src/components/Atoms/Input/Input';
import { useSendProducts, useSendProductById } from '../SendProductHooks/SendProduct.hooks';
import Select from '@src/components/Atoms/Select/Select';
import '@src/app/styles/datatable.css';
import RecordTalent from '@src/components/molecules/Record/Talent';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { initialSendProductValues } from '@src/app/pages/SendProductPage/SendProduct/SendProduct.types';
import SendProductForm from '@src/components/Atoms/Drawer/SendProductFormDrawer/SendProductForm';
import Date from '@src/components/Atoms/Date/Date';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useSocialMedia } from '../../SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { useTeams } from '../../TeamPage/TeamHooks/Team.hooks';
import { useDivisions } from '../../DivisionPage/DivisionHooks/Division.hooks';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import dayjs from 'dayjs';
import Loading from '@src/components/Atoms/Loading/Loading';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import SendProductActionDropdown from '@src/components/Atoms/ActionDropdown/SendProductActionDropdown';
import SendProductDetailTalent from '../SendProductDetail/SendProductDetailTalent';
import { capitalize } from '@src/utils/commonUtils';
import ApprovalActionKirimBarang from '@src/app/modules/send-product/ApprovalActionKirimBarang';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Kirim Barang',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var SendProductFormSchema = Yup.object({
    talentPubId: Yup.string().required('Talent wajib diisi'),
    talentAddressPubId: Yup.string().required('Alamat wajib diisi'),
    courierPubId: Yup.string().required('Kurir wajib diisi'),
    address: Yup.object().when('talentAddressPubId', {
        is: function (val) { return val === 'add-new'; },
        then: Yup.object({
            address: Yup.string().required('Alamat wajib diisi'),
            cityPubId: Yup.string().required('Kota wajib diisi'),
            recipientName: Yup.string().required('Nama penerima wajib diisi'),
            recipientPhoneNumber: Yup.string().required('Nomor penerima wajib diisi'),
        }),
        otherwise: Yup.object().nullable(),
    }),
    jobPubId: Yup.string(),
    teamPubId: Yup.string().required('Tim wajib diisi'),
});
var SendProductIndex = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var _m = _a.disableBreadcrumbs, disableBreadcrumbs = _m === void 0 ? false : _m;
    var navigate = useNavigate();
    var intl = useIntl();
    var id = useParams().id;
    var role = JSON.parse(userInformation).roles[0];
    //initialize filter
    var _o = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[["isRejected", "false"]]',
        orderBy: "[[\"".concat(optionRekapList[0].value, "\", \"desc\"]]"),
        Option: capitalize(optionRekapList[0].value),
    }), filter = _o[0], setFilter = _o[1];
    var _p = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
        initialFilterBy: '[]',
    }), filterPageSize = _p[0], setFilterPageSize = _p[1];
    //getdata
    var _q = useState([
        {
            productPubId: '',
            productCategoryPubId: '',
            qty: 1,
        },
    ]), newVar = _q[0], setNewVar = _q[1];
    var _r = useState('Kirim Barang'), tabName = _r[0], setTabName = _r[1];
    var _s = useState(undefined), editId = _s[0], setEditId = _s[1];
    var _t = useState(), selectedRecord = _t[0], setSelectedRecord = _t[1];
    var _u = useState(undefined), talentId = _u[0], setTalentId = _u[1];
    var _v = useState(false), isOpenAdd = _v[0], setIsOpenAdd = _v[1];
    var _w = useState(false), enabledSendProduct = _w[0], setEnabledSendProduct = _w[1];
    var _x = useSendProducts(filter, enabledSendProduct), sendProducts = _x.sendProducts, isLoading = _x.isLoading, refetchSendProduct = _x.refetch;
    var _y = useSendProducts(filter, enabledSendProduct), createSendProduct = _y.createSendProduct, updateSendProduct = _y.updateSendProduct, sentSendProduct = _y.sentSendProduct;
    var sendProduct = useSendProductById(editId).data;
    var _z = useState(false), isOpenDetail = _z[0], setIsOpenDetail = _z[1];
    var sendProductValues = {
        talentPubId: (_b = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.talent.pubId) !== null && _b !== void 0 ? _b : '',
        talentAddressPubId: (_c = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.address.pubId) !== null && _c !== void 0 ? _c : '',
        jobPubId: (_e = (_d = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.job) === null || _d === void 0 ? void 0 : _d.pubId) !== null && _e !== void 0 ? _e : '',
        teamPubId: (_g = (_f = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.team) === null || _f === void 0 ? void 0 : _f.pubId) !== null && _g !== void 0 ? _g : '',
        courierPubId: (_h = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.courier.pubId) !== null && _h !== void 0 ? _h : '',
        details: [],
        sendProduct: {
            products: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.details.map(function (obj, key) { return ({
                productPubId: obj.product.pubId,
                productCategoryPubId: obj.product.productCategory.pubId,
                qty: obj.qty,
            }); }),
        },
        products: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.details.map(function (obj, key) { return ({
            productPubId: obj.product.pubId,
            productCategoryPubId: obj.product.productCategory.pubId,
            qty: obj.qty,
        }); }),
        note: (_j = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.note) !== null && _j !== void 0 ? _j : '',
        address: null,
    };
    //fiters
    var socialMedia = useSocialMedia().socialMedia;
    var team = useTeams(filterPageSize).team;
    var divisions = useDivisions().divisions;
    var teams = { data: {} };
    if (role === RoleList.CampaignExecutive) {
        socialMedia = JSON.parse(userInformation).socialMedias;
        divisions = JSON.parse(userInformation).divisions;
        teams.data = JSON.parse(userInformation).teams;
    }
    var optionSocialMedia = socialMedia === null || socialMedia === void 0 ? void 0 : socialMedia.map(function (social) { return ({
        name: social.name,
        value: social.pubId,
    }); });
    role !== RoleList.CampaignExecutive &&
        optionSocialMedia &&
        optionSocialMedia.unshift(DefaultAllList);
    var optionDivision = role === RoleList.CampaignExecutive
        ? divisions &&
            divisions.map(function (division, index) { return ({
                name: "".concat(division.name, " - ").concat(socialMedia && socialMedia[index].name),
                value: division.pubId,
            }); })
        : divisions &&
            divisions.map(function (division) { return ({
                name: "".concat(division.name, " - ").concat(division.socialMedia.name),
                value: division.pubId,
            }); });
    var optionTeam = role === RoleList.CampaignExecutive
        ? teams.data &&
            teams.data.map(function (team) { return ({
                name: team.name,
                value: team.pubId,
            }); })
        : (team === null || team === void 0 ? void 0 : team.data) &&
            team.data.map(function (team) { return ({
                name: team.name,
                value: team.pubId,
            }); });
    role !== RoleList.CampaignExecutive && optionTeam && optionTeam.unshift(DefaultAllList);
    var _0 = useState(dayjs().format('YYYY-MM-DD').toString()), date = _0[0], setDate = _0[1];
    var onChangeDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'date';
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    //pagination
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    //search & filters
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    //add
    var addItem = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectedRecord(undefined);
            SendProductFormik.setValues(initialSendProductValues);
            setEditId(undefined);
            setTalentId(undefined);
            setIsOpenAdd(true);
            return [2 /*return*/];
        });
    }); };
    //edit
    var editItem = function (record) {
        setEditId(record.pubId);
        setTalentId(record.talent.pubId);
        setSelectedRecord(record);
        // SendProductFormik.values.talent = record.talent
        setIsOpenAdd(true);
    };
    //process
    var processItem = function (record) {
        swal({
            title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
            text: '',
            icon: 'warning',
            buttons: [
                intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
            ],
            dangerMode: true,
        }).then(function (willProcess) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!willProcess) return [3 /*break*/, 6];
                        if (!record.isApproved) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, sentSendProduct(record.pubId)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        // toast.error(error.message)
                        toast.error('Error: Produk tidak memiliki SKU');
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        toast.error('Pengiriman belum di approve');
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); });
    };
    var handleDetail = function (record) {
        setIsOpenDetail(true);
        setEditId(record.pubId);
    };
    var SendProductFormik = useFormik({
        initialValues: sendProduct
            ? sendProductValues
            : __assign(__assign({}, initialSendProductValues), { talentPubId: (_k = selectedRecord === null || selectedRecord === void 0 ? void 0 : selectedRecord.talent) === null || _k === void 0 ? void 0 : _k.pubId, talentAddressPubId: (_l = selectedRecord === null || selectedRecord === void 0 ? void 0 : selectedRecord.address) === null || _l === void 0 ? void 0 : _l.pubId }),
        enableReinitialize: true,
        validationSchema: SendProductFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var courierPubId, jobPubId, note, sendProduct, talentAddressPubId, talentPubId, address, teamPubId, products, formData, addressPubId, e_2, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        courierPubId = values.courierPubId, jobPubId = values.jobPubId, note = values.note, sendProduct = values.sendProduct, talentAddressPubId = values.talentAddressPubId, talentPubId = values.talentPubId, address = values.address, teamPubId = values.teamPubId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        products = sendProduct.products;
                        formData = {
                            courierPubId: courierPubId,
                            jobPubId: jobPubId,
                            note: note,
                            products: products,
                            talentAddressPubId: talentAddressPubId,
                            talentPubId: talentPubId,
                            address: address,
                            teamPubId: teamPubId,
                        };
                        addressPubId = talentAddressPubId === 'add-new' ? '' : talentAddressPubId;
                        if (!editId) return [3 /*break*/, 3];
                        return [4 /*yield*/, updateSendProduct(__assign(__assign({ id: editId }, formData), { talentAddressPubId: addressPubId }))];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, createSendProduct(__assign(__assign({}, formData), { talentAddressPubId: addressPubId }))];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_2 = _a.sent();
                        error = e_2.response.data;
                        toast.error(error);
                        return [3 /*break*/, 8];
                    case 7:
                        setIsOpenAdd(false);
                        setEditId(undefined);
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        }); },
    });
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    var columns = [
        {
            name: 'No Pesanan',
            selector: function (row) { return row.orderNumber; },
            sortable: true,
            width: '170px',
        },
        {
            name: 'Nama',
            cell: function (record) { return (_jsx(RecordTalent, { name: record.talent.name, pubId: record.talent.pubId, cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber, cpEmail: record.talent.cpEmail ? record.talent.cpEmail : '', onClick: gotoDetail })); },
            sortable: true,
        },
        {
            name: 'Alamat',
            cell: function (record) {
                return (_jsx("div", __assign({ className: '' }, { children: _jsxs("p", { children: [record.address.address, ", ", record.address.city.name, ",", ' ', record.address.city.province.name] }) })));
            },
            sortable: true,
        },
        {
            name: 'Kurir',
            selector: function (row) { return row.courier.name; },
            wrap: true,
            sortable: true,
        },
        {
            name: 'Barang',
            cell: function (record) {
                return record.details.map(function (obj, key) {
                    return (_jsx("div", { children: _jsxs("p", __assign({ className: 'createdby mb-2' }, { children: ["[", obj.product.productCategory.name, "] ", obj.product.name, ", ", obj.qty, " pc(s)"] })) }, key));
                });
            },
            sortable: true,
        },
        {
            name: 'Approved',
            cell: function (record) {
                return (_jsx("div", { children: record.isApproved ? (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.approvedBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.approvedOn) }))] }))) : ('-') }));
            },
            sortable: true,
        },
        {
            name: 'Sent',
            cell: function (record) {
                return (_jsx("div", { children: record.isSent ? (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.sentBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.sentOn) }))] }))) : ('-') }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record, isShow: false }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsxs("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: [_jsx(SendProductActionDropdown, { handleDetail: handleDetail, handleEdit: editItem, record: record, handleSent: processItem, id: 'record.pubId' }), _jsx(ApprovalActionKirimBarang, { sendProductPubId: record.pubId, sendProduct: record })] })));
            },
        },
    ];
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (socialMedia &&
            socialMedia.length > 0 &&
            optionSocialMedia &&
            optionSocialMedia[0].value !== '') {
            var filterCriteria = ['socialMedia.pubId', socialMedia && socialMedia[0].pubId];
            filterValue.push(filterCriteria);
        }
        if ((team && team.data && team.data.length > 0) ||
            (teams && teams.data && teams.data.length > 0)) {
            var filterCriteria = ['team.pubId', optionTeam && optionTeam[0].value];
            filterValue.push(filterCriteria);
        }
        if (date) {
            var filterCriteria = ['date', date];
            filterValue.push(filterCriteria);
        }
        if (role === RoleList.Audit) {
            filterValue.push(['isApproved', 'true']);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
    }, []);
    useEffect(function () {
        setEnabledSendProduct(true);
        refetchSendProduct();
    }, []);
    return (_jsxs(Fragment, { children: [!disableBreadcrumbs ? (_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Kirim Barang" }))) : undefined, _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsxs("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: [_jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                    handleFilterChange(e);
                                                } }) })), role !== RoleList.Admin ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: optionSocialMedia && optionSocialMedia.length > 0 && (_jsx(Select, { name: 'talent.division.socialMedia.pubId', onChange: function (e) {
                                                            handleMultiDimensionalFilterChange(e);
                                                        }, placeholder: 'Filter by Social Media', optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value, isPlaceholder: false })) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionDivision && optionDivision.length > 0 && (_jsx(Select, { name: 'talent.division.pubId', onChange: function (e) {
                                                            handleMultiDimensionalFilterChange(e);
                                                        }, placeholder: 'Filter by Division', optionList: optionDivision })) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionTeam && optionTeam.length > 0 && (_jsx(Select, { name: 'team.pubId', onChange: function (e) {
                                                            handleMultiDimensionalFilterChange(e);
                                                        }, placeholder: 'Filter by Teams', optionList: optionTeam, defaultValue: optionTeam[0].value, isPlaceholder: false })) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'rekap' //change
                                                        , onChange: function (e) {
                                                            setFilter(__assign(__assign({}, filter), { orderBy: "[[\"".concat(e.target.value, "\", \"desc\"]]"), Option: capitalize(e.target.value) }));
                                                        }, optionList: optionRekapList, defaultValue: optionRekapList[0].value }) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Date, { date: date, handleonchange: onChangeDate }) }))] })) : undefined] })), _jsx("div", __assign({ className: 'card-toolbar justify-content-end' }, { children: _jsx("div", __assign({ className: 'd-flex justify-content-end', "data-kt-talentcategory-table-toolbar": 'base' }, { children: _jsx("div", __assign({ className: 'me-3' }, { children: _jsxs("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary', onClick: addItem }, { children: [_jsx(KTSVG, { path: '/media/icons/duotune/arrows/arr075.svg', className: 'svg-icon-2' }), "Tambah"] })) })) })) }))] })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: sendProducts ? sendProducts.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: sendProducts && sendProducts.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }) }))] }), _jsx(SendProductContextProvider, __assign({ currentData: selectedRecord }, { children: isOpenAdd && (_jsx(DrawerForm, { tabName: tabName, useForm: SendProductFormik, isOpen: isOpenAdd, handleClose: setIsOpenAdd, onSubmit: SendProductFormik.handleSubmit, component: SendProductForm, idForEdit: talentId, size: 'LG', editableValue: newVar, setEditableValue: setNewVar })) })), _jsx(SendProductDetailTalent, { isOpen: isOpenDetail, handleClose: setIsOpenDetail, record: sendProduct })] }));
};
export var SendProductContext = createContext({});
export function SendProductContextProvider(_a) {
    var children = _a.children, currentData = _a.currentData;
    return _jsx(SendProductContext.Provider, __assign({ value: currentData }, { children: children }));
}
export default SendProductIndex;
