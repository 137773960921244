var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import DataTable from 'react-data-table-component';
import { FormatDateEpoch } from '@src/app/helper';
var ListHistory = function (_a) {
    var data = _a.data;
    var columns = [
        {
            name: 'Reason',
            selector: function (row) { return row.listedReason !== ' ' ? row.listedReason : '-'; },
            sortable: true,
        },
        {
            name: 'Type',
            selector: function (row) { return row.isListed ? 'List' : 'Unlist'; },
            sortable: true,
        },
        {
            name: 'By',
            cell: function (row) {
                return (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: 'mb-1' }, { children: row.listedBy })), _jsx("p", __assign({ className: 'mb-1' }, { children: FormatDateEpoch(row.listedOn) }))] }));
            },
            sortable: true,
        },
    ];
    return (_jsx("div", __assign({ className: 'mt-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: data, noHeader: true }) })));
};
export default ListHistory;
