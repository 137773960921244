export var JobTypeEnum;
(function (JobTypeEnum) {
    JobTypeEnum[JobTypeEnum["NotYetChat"] = 0] = "NotYetChat";
    JobTypeEnum[JobTypeEnum["DoneChat"] = 1] = "DoneChat";
    JobTypeEnum[JobTypeEnum["Nego"] = 2] = "Nego";
    JobTypeEnum[JobTypeEnum["NegoOnly"] = 2] = "NegoOnly";
    JobTypeEnum[JobTypeEnum["OnHold"] = 3] = "OnHold";
    JobTypeEnum[JobTypeEnum["Rejected"] = 4] = "Rejected";
    JobTypeEnum["All"] = "";
})(JobTypeEnum || (JobTypeEnum = {}));
export var FilterStatus;
(function (FilterStatus) {
    FilterStatus["NotYetChat"] = "NotYetChat";
    FilterStatus["Done"] = "Done";
    FilterStatus["DoneChat"] = "DoneChat";
    FilterStatus["Nego"] = "Nego";
    FilterStatus["NegoOnly"] = "NegoOnly";
    FilterStatus["OnHold"] = "OnHold";
    FilterStatus["Rejected"] = "Rejected";
    FilterStatus["Open"] = "Open";
    FilterStatus["NegoSpv"] = "NegoSpv";
    FilterStatus["Cancel"] = "Cancel";
    FilterStatus["OnReview"] = "OnReview";
    FilterStatus["Approved"] = "Approved";
    FilterStatus["All"] = "All";
    FilterStatus["Default"] = "";
})(FilterStatus || (FilterStatus = {}));
export var optionJobTypeAll = [
    {
        value: FilterStatus.All,
        name: 'Semua',
    },
    {
        value: FilterStatus.Nego,
        name: 'Nego',
    },
    {
        value: FilterStatus.NegoOnly,
        name: 'Nego Only',
    },
    {
        value: FilterStatus.OnHold,
        name: 'On Hold',
    },
    {
        value: FilterStatus.Rejected,
        name: 'Ditolak',
    },
];
export var optionJobType = [
    {
        value: FilterStatus.NotYetChat,
        name: 'Belum Dichat',
    },
    {
        value: FilterStatus.DoneChat,
        name: 'Done Chat',
    },
    {
        value: FilterStatus.Nego,
        name: 'Nego',
    },
    {
        value: FilterStatus.NegoOnly,
        name: 'Nego Only',
    },
    {
        value: FilterStatus.OnHold,
        name: 'On Hold',
    },
    {
        value: FilterStatus.Rejected,
        name: 'Ditolak',
    },
];
export var optionJobNegoStatus = [
    {
        value: FilterStatus.Default,
        name: 'Semua',
    },
    {
        value: FilterStatus.Open,
        name: 'Open',
    },
    {
        value: FilterStatus.NegoSpv,
        name: 'NegoSpv',
    },
    {
        value: FilterStatus.Nego,
        name: 'Nego',
    },
    {
        value: FilterStatus.Approved,
        name: 'Approved',
    },
    {
        value: FilterStatus.Cancel,
        name: 'Cancel',
    },
];
export var optionApprovals = [
    {
        value: FilterStatus.OnReview,
        name: 'On Review',
    },
    {
        value: FilterStatus.OnHold,
        name: 'On Hold',
    },
];
export var optionOnHold = [
    {
        value: FilterStatus.NegoOnly,
        name: 'Nego',
    },
    {
        value: FilterStatus.OnHold,
        name: 'On Hold',
    },
];
export var followerCategoriesEnum;
(function (followerCategoriesEnum) {
    followerCategoriesEnum["All"] = "";
    followerCategoriesEnum["SUPERNANO"] = "Super Nano";
    followerCategoriesEnum["NANO"] = "Nano";
    followerCategoriesEnum["MIKRO"] = "Mikro";
    // MIDTIER = 'Mid',
    followerCategoriesEnum["MAKRO"] = "Makro";
    followerCategoriesEnum["MEGA"] = "Mega";
})(followerCategoriesEnum || (followerCategoriesEnum = {}));
export var followerCategoriesOptions = [
    {
        value: followerCategoriesEnum.All,
        label: 'Semua'
    },
    {
        value: followerCategoriesEnum.SUPERNANO,
        label: followerCategoriesEnum.SUPERNANO,
    },
    {
        value: followerCategoriesEnum.NANO,
        label: followerCategoriesEnum.NANO,
    },
    {
        value: followerCategoriesEnum.MIKRO,
        label: followerCategoriesEnum.MIKRO,
    },
    // {
    //   value: followerCategoriesEnum.MIDTIER,
    //   label: followerCategoriesEnum.MIDTIER,
    // },
    {
        value: followerCategoriesEnum.MAKRO,
        label: followerCategoriesEnum.MAKRO,
    },
    {
        value: followerCategoriesEnum.MEGA,
        label: followerCategoriesEnum.MEGA,
    },
];
