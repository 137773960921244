import { PajakCategory, PajakMethod, PajakType, TransferMethod, PositionList, TransferType, NpwpStatus, TermStatus, SOWStatus, } from './Transfer.enum';
export var tandaPengenalOption = [
    { name: 'KTP', value: 'ktp' },
    { name: 'Passport', value: 'passport' },
];
export var dummyTransferOption = [
    {
        name: 'Transfer',
        value: TransferType.TRANSFER,
    },
    {
        name: 'Cash',
        value: TransferType.CASH,
    },
];
export var dummyPaymentMethodOptions = [
    {
        name: 'Full',
        value: TransferMethod.FULL,
    },
    {
        name: 'Per Term',
        value: TransferMethod.PERTERM,
    },
];
export var dummyPositionOptions = [
    {
        name: 'Kuasa Direksi',
        value: PositionList.manager,
    },
    {
        name: 'Direktur',
        value: PositionList.director,
    },
];
export var dummyTransferMethodGroupOptions = [
    {
        name: 'Normal',
        value: TransferMethod.NORMAL,
    },
    {
        name: 'Transfer per Termin',
        value: TransferMethod.TRANSFERPERTERM,
    },
];
export var dummyTransferMethodOptions = [
    {
        name: 'Normal',
        value: TransferMethod.NORMAL,
    },
    {
        name: 'Transfer per Termin',
        value: TransferMethod.TRANSFERPERTERM,
    },
    {
        name: 'Transfer per Group',
        value: TransferMethod.TRANSFERASGROUP,
    },
];
export var dummyTaxOptions = [
    {
        name: 'Pajak',
        value: PajakType.PAJAK,
    },
    {
        name: 'Non Pajak',
        value: PajakType.NONPAJAK,
    },
];
export var dummyNpwpStatusOptions = [
    {
        value: NpwpStatus.NPWP,
        name: 'Have NPWP',
    },
    {
        value: NpwpStatus.NotNPWP,
        name: 'Dont Have NPWP',
    },
];
export var dummySOWOptions = [
    {
        name: 'Sesuai SOW',
        value: SOWStatus.SOW,
    },
    {
        name: 'Tidak Sesuai SOW',
        value: SOWStatus.NotSOW,
    },
];
export var dummyTaxMethodOptions = [
    {
        name: 'Include',
        value: PajakMethod.INCLUDED,
    },
    {
        name: 'Exclude',
        value: PajakMethod.EXCLUDED,
    },
];
export var dummyTaxTypeOptions = [
    {
        name: 'PPh 21',
        value: PajakCategory.PPH21,
    },
    {
        name: 'PPh 23',
        value: PajakCategory.PPH23,
    },
    {
        name: 'PPh 23 Plus PPN Exclude',
        value: PajakCategory.PPh23PlusPPNExc,
    },
    {
        name: 'PPH 23 Plus PPN Include',
        value: PajakCategory.PPh23PlusPPNInc,
    },
    {
        name: 'PPh 26 0%',
        value: PajakCategory.PPH26,
    },
    {
        name: 'PPh 26 20%',
        value: PajakCategory.PPH2620Percent,
    },
    {
        name: 'PPh 4 Ayat 2',
        value: PajakCategory.PPh4ayat2,
    },
    {
        name: 'PPh 4 Ayat 2 Plus PPN Include',
        value: PajakCategory.PPh4ayat2PlusPPNInc,
    },
    {
        name: 'PPh 4 Ayat 2 Plus PPN Exclude',
        value: PajakCategory.PPh4ayat2PlusPPNExc,
    },
];
export var dummySPKTaxTypeOptions = [
    {
        name: 'PPh 21',
        value: PajakCategory.PPH21,
    },
    {
        name: 'PPh 23',
        value: PajakCategory.PPH23,
    },
    {
        name: 'PPh 23 + PPn Include',
        value: PajakCategory.PPh23PlusPPNInc,
    },
    {
        name: 'PPh 23 + PPn Exclude',
        value: PajakCategory.PPh23PlusPPNExc,
    },
    {
        name: 'PPh 24 Ayat 2',
        value: PajakCategory.PPh4ayat2,
    },
    {
        name: 'PPh 24 + PPn Include',
        value: PajakCategory.PPh24PlusPPNInc,
    },
    {
        name: 'PPh 24 + PPn Exclude',
        value: PajakCategory.PPh24PlusPPNExc,
    },
];
export var StatusOptions = [
    {
        name: 'Ada Pembayaran Lanjutan',
        value: TermStatus.NEXTTERM,
    },
    {
        name: 'Pembayaran Selesai',
        value: TermStatus.ALLTERMSCOMPLETED,
    },
];
export var SPKOptions = [
    {
        name: 'Personal',
        value: 'Personal',
    },
    {
        name: 'Manager',
        value: 'Manager',
    },
    {
        name: 'Company',
        value: 'Company',
    },
];
export var InvoiceOptions = [
    {
        name: 'Personal',
        value: 'Personal',
    },
    {
        name: 'Manager',
        value: 'Manager',
    },
];
export var transferInitialValues = {
    paymentMethod: '',
    accountNumber: '',
    taxStatus: '',
    transferTotal: '',
    note: '',
    transferProof: '',
    status: '',
    transferSource: '',
};
